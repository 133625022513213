<script lang="ts" setup>
import { last } from 'lodash-es';
import { TransitionRoot, Dialog, DialogPanel, DialogTitle, DialogDescription } from '@headlessui/vue';
import { ref } from '#imports';

interface IDialogProps {
  modelValue: boolean;
  title?: string;
  description?: string;
  illustration?: string;
  dismissable?: boolean;
  verticalActions?: boolean;
  actions?: Array<{ text: string; variant?: 'default' | 'cancel'; onPress?: () => void }>;
}

interface Emits {
  (e: 'update:modelValue', value: boolean): void;
  (e: 'close'): void;
}

const props = withDefaults(defineProps<IDialogProps>(), { dismissable: true });

const emit = defineEmits<Emits>();

const actionRefs = ref([]);

const illustrationV2 = props.illustration?.startsWith('v2/');

function closeModal() {
  emit('update:modelValue', false);
  emit('close');
}
</script>

<template>
  <TransitionRoot
    unmount
    as="template"
    :show="modelValue"
  >
    <Dialog
      :initial-focus="last(actionRefs)"
      as="div"
      class="relative z-50"
      @close="() => dismissable && closeModal()"
    >
      <div class="fixed inset-0 bg-black bg-opacity-40" />

      <div class="fixed inset-0 overflow-y-auto">
        <div class="flex min-h-full items-center justify-center text-center p-40">
          <DialogPanel
            class="flex flex-col items-center justify-between max-w-2xl transform overflow-hidden rounded p-30 bg-white text-left align-middle shadow-xl transition-all sm:flex-row sm:px-50 sm:py-30 sm:w-fit sm:gap-x-50"
          >
            <ApzButton
              v-if="dismissable"
              variant="clear"
              size="small"
              class="w-[30px] h-[30px] absolute top-10 right-10"
              @click="closeModal"
            >
              <ApzIcon
                icon="times"
                class="sm:text-[23px]"
                size="lg"
                fixed-width
              />
            </ApzButton>

            <ApzIllustration
              v-if="illustration && !illustrationV2"
              :name="illustration"
              :width="$device.isMobile ? 160 : 200"
              :height="$device.isMobile ? 160 : 200"
              :class="[$device.isMobile ? 'h-[160px]' : 'h-[200px]', 'object-contain mb-20 sm:mb-0 sm:order-1']"
            />

            <ApzIllustration
              v-if="illustration && illustrationV2"
              :name="illustration"
              :width="$device.isMobile ? 180 : 200"
              :height="$device.isMobile ? 180 : 200"
              :class="[
                $device.isMobile ? 'h-[140px] w-[140px]' : 'h-[160px] w-[160px]',
                'object-contain mb-20 sm:mb-0 sm:order-1',
              ]"
            />

            <div class="flex flex-col items-center text-center sm:items-start sm:text-start">
              <slot name="title">
                <DialogTitle
                  as="h3"
                  class="text-base font-bold sm:text-medium max-w-[220px] sm:max-w-[250px]"
                >
                  {{ title }}
                </DialogTitle>
              </slot>

              <slot name="description">
                <DialogDescription
                  v-if="description"
                  class="text-small mt-10 sm:mt-15 sm:text-small max-w-[220px] sm:max-w-[250px]"
                >
                  {{ description }}
                </DialogDescription>
              </slot>

              <slot
                name="actions"
                :close="closeModal"
              >
                <div
                  class="flex gap-10 mt-20 sm:mt-30"
                  :class="{ 'flex-col w-full': verticalActions, 'flex-col w-[220px]': $device.isMobile }"
                >
                  <template v-if="actions?.length">
                    <ApzButton
                      v-for="(action, index) in actions"
                      :key="index"
                      ref="actionRefs"
                      class="px-20 !h-35 !text-small"
                      :class="[action.variant === 'cancel' && '!bg-[#FFE9EA] text-primary']"
                      :type="action.variant === 'cancel' ? 'default' : 'primary'"
                      @click="() => action.onPress?.() || closeModal()"
                    >
                      {{ action.text }}
                    </ApzButton>
                  </template>

                  <ApzButton
                    v-else
                    type="primary"
                    class="min-w-[105px]"
                    @click="closeModal"
                  >
                    {{ $t('global.ok') }}
                  </ApzButton>
                </div>
              </slot>
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
